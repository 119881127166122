import React, { Component } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { StaticImage } from "gatsby-plugin-image";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import SEO from "../../components/seo";
import { Helmet } from "react-helmet"

class folia extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      name: "",
      phone: "",
      apartment: "",
      category: "",
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.sendMessage = this.sendMessage.bind(this);
  }

  handleInputChange(event) {
    event.preventDefault();
    const target = event.target;
    const name = target.name;
    const value = target.value;
    this.setState({ [name]: value });
  }

  sendMessage(event) {
    event.preventDefault();
    const apiUrl = "https://birk.zendesk.com/api/v2/requests.json";
    const formData = {
      request: {
        requester: { name: `${this.state.name}` },
        subject: "folia",
        comment: {
          body: `Email:${this.state.email} , Phone-number:${this.state.phone}  , Apartment-number:${this.state.apartment} , category:${this.state.category}`,
        },
      },
    };

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    // BODY: {"request": {"requester": {"name": "iTechNotion customer"}, "subject": "Help!", "comment": {"body": "My printer is on fire!" }}}

    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(formData),
    };

    fetch(apiUrl, options, `Jrf7BjvmmAs4sSEGmyiHk9c5SCvtwuk3e6c5Sh1u`)
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            email: this.state.email,
            name: this.state.name,
            phone: this.state.phone,
            apartment: this.state.apartment,
            category: this.state.category,
          });
          toast.success(
            "Thank you for registering your interest with Birk.We will get back to you soon",
            {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            }
          );
        },
        (error) => {
          this.setState({ error });
          toast.error("Something went wrong. Please try again", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      );
    let data = {
      name: this.state.name,
      email: this.state.email,
      phone: this.state.phone,
      apartment: this.state.apartment,
      category: this.state.category,
    };
    axios
      .post("/api/forma", data)
      .then((res) => {
        console.log("Success everywhere", res);
      })
      .catch(() => {
        console.log("Error everywhere");
      });
    this.setState({
      email: "",
      name: "",
      phone: "",
      apartment: "",
      category: "",
    });
  }
  render() {
    return (
      <React.Fragment>
        <SEO />
        <Helmet>
          <link rel="canonical" href="http://birk.com.au" />
        </Helmet>
        <Header />
        <ToastContainer />
        <section className="folio-section">
          <div className="container-fluid all-section">
            <div className="row align-items-center justify-content-center">
              <div className="col-lg-12 col-xl-6">
                <div className="heading">
                  <h2>FINISHING TOUCHES BY BIRK</h2>
                </div>
                <div className="about-form">
                  <div className="folio-subtext">
                    <h3>Your designer window furnishings</h3>
                    <p>
                      Window furnishings are an important final addition to add
                      a layer of luxury, privacy and functionality to your new
                      Folia apartment.
                    </p>
                    <p>
                      Our expert Design Consultants are here to help bring your
                      vision to life, ensuring every detail of your window
                      furnishings are considered and delivered to the highest
                      standard.
                    </p>
                    <p>
                      To learn more, register your details below to download
                      your complimentary Birk Style Guide.
                    </p>
                  </div>
                  <form onSubmit={this.sendMessage}>
                    <div className="form-group">
                      <label htmlFor="folio-Name" className="font-weight-bold">
                        Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="folio-Name"
                        placeholder="Name"
                        name="name"
                        value={this.state.name}
                        onChange={this.handleInputChange.bind(this)}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="folio-Phone" className="font-weight-bold">
                        Phone number
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="folio-Phone"
                        placeholder="Phone number"
                        name="phone"
                        value={this.state.phone}
                        onChange={this.handleInputChange.bind(this)}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="folio-Email" className="font-weight-bold">
                        Email address
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="folio-Email"
                        placeholder="Email address"
                        name="email"
                        value={this.state.email}
                        onChange={this.handleInputChange.bind(this)}
                      />
                    </div>

                    <div className="form-group">
                      <label
                        htmlFor="folio-Apartment"
                        className="font-weight-bold"
                      >
                        Apartment number
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="folio-Apartment"
                        placeholder="Apartment number"
                        name="apartment"
                        value={this.state.apartment}
                        onChange={this.handleInputChange.bind(this)}
                      />
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="folio-Owner-Investor"
                        className="font-weight-bold"
                      >
                        Owner Occupier or Investor
                      </label>
                      <select
                        className="form-control"
                        id="folio-Owner-Investor"
                        required
                        name="category"
                        onBlur={this.handleInputChange.bind(this)}
                      >
                        <option value="" disabled selected hidden>
                          -- select --
                        </option>
                        <option value="Owner Occupier">Owner Occupier</option>
                        <option value="Investor">Investor</option>
                      </select>
                    </div>

                    <button
                      type="submit"
                      className="btn btn-dark text-uppercase mb-4"
                      onClick={this.sendMessage}
                    >
                      submit
                    </button>
                  </form>
                </div>
              </div>

              <div className="col-lg-12 col-xl-6">
                <div className="about-form-img folio-outer-img">
                  <StaticImage
                    src="../../images/folio-img.png"
                    alt=""
                    className="img-fluid"
                  />
                  <div className="folio-inner-img">
                    <StaticImage
                      src="../../images/folia_logo_circ.png"
                      alt=""
                      className="img-fluid "
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </React.Fragment>
    );
  }
}
export default folia;
